import './About.scss'
import { WorksContext } from '../../utils/WorksContext'
import React, { useContext, useEffect } from 'react'
 
function About() {

    const { setFooterUnfold, setBackgroundStyle, language } = useContext(WorksContext);
    useEffect(() => {
        setFooterUnfold(true);
        setBackgroundStyle('about')
    }, []);

    return  (      
        <section className='aboutSection'>
            <div className='aboutSection_infos'>
                <h1 className='aboutSection_infos_title'>BEN GIBERT</h1>
                <h2 className='aboutSection_infos_secondTitle'>{language==="en" ? 'FRONT-END DEVELOPER , WEBDESIGNER' : 'DÉVELOPPEUR FRONT-END , WEBDESIGNER'}</h2>
                <p className='aboutSection_infos_description'>{language==="en" ?"In the field of electronic music, I awakened my interest in the development of tools and applications. Now as a front-end developer, I am dedicated to refining my skills in webdesign and UX/UI. I love beautiful interfaces, engaging user experiences, and I am constantly learning new technologies. This ongoing commitment allows me to contribute a unique perspective to web applications." : "C'est dans le domaine de la programmation en musiques électroniques que j'ai découvert une appétence pour le développement d'outils et d'applications. En tant que développeur front-end aujourd'hui, je continue à étoffer ma créativité et à affiner mes compétences en design web et UX/UI. Aussi je m'emploie constamment à me former sur de nouvelles technologies, afin d'apporter une perspective unique aux applications web que je développe."}</p>
            </div>
            <div className='aboutSection_myselfImage' >
                <img src="./assets/traits.svg" alt="portrait" width="20%" />
            </div>
        </section>
    )
}

export default About