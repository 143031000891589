import './Work.scss'
import { useContext, useEffect } from 'react'
import { WorksContext } from '../../utils/WorksContext'
import { Link, useParams, useNavigate } from 'react-router-dom'


function Work() {

    const { works, setFooterUnfold, setBackgroundStyle, language } = useContext(WorksContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const index = works.findIndex((work) => work._id === id);
    const work = works[index];
    const worksLength = works.length;
    
    useEffect(() => {
        if (index === -1) {
            navigate('*');
          }
        setFooterUnfold(true);
        setBackgroundStyle('work');
    }, [index, navigate]);
    
    
    if (index === -1) {
    return null;
    }

    if (!works || worksLength === 0) {
        return <div>Aucune donnée disponible</div>;
      }

    return (
        <section className="work">
            <Link to="/works"><img className='work_backArrow' src='../assets/fleche.svg' alt='fleche'/></Link>
            <a href={`https://${work.linkUrl}`} target="_blank" rel="noreferrer">
                <h3 className="work_title">{work.title}</h3>
            </a>
            <div className="work_datasWrapper">
                <p className="work_description">{language==="en" ? work.descriptionEn : work.descriptionFr}</p>
                <div className="work_infos">
                    <div className="work_infos_technos">
                        <div className="work_infos_technos_title">
                            <p>T</p>
                            <p>E</p>
                            <p>C</p>
                            <p>H</p>
                            <p>N</p>
                            <p>O</p>
                            <p>S</p>
                        </div>
                        {/* <p className="work_infos_technos_title">TECHNOLOGIES</p> */}
                        <p className="work_infos_technos_text">{`${work.technos}`}</p>
                    </div>
                    <div className="work_infos_links">
                        <a href={`https://${work.githubLink}`} target="_blank" rel="noreferrer">
                            <img src='../assets/github.svg' alt='lien github' className='work_infos_links_github'/>
                        </a>
                        <a href={`https://${work.linkUrl}`} target="_blank" rel="noreferrer" >
                            <img src='../assets/link_icon.svg' alt='lien website' className='work_infos_links_websiteUrl'/>
                        </a>
                    </div>
                    <div className="work_infos_challenges">
                        <div className="work_infos_challenges_title">
                            <p>C</p>
                            <p>H</p>
                            <p>A</p>
                            <p>L</p>
                            <p>L</p>
                            <p>E</p>
                            <p>N</p>
                            <p>G</p>
                            <p>E</p>
                            <p>S</p>
                        </div>
                        <p className="work_infos_challenges_text">{language==="en" ? work.challengesEn : work.challengesFr}</p>
                    </div>
                </div>
            </div>
            <div className="work_image" style={{ '--image-url': `url(${work.imageUrl})` }}>
            </div>
        </section>
    )
}

export default Work