
import './Header.scss'
import { Link, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { WorksContext } from '../../utils/WorksContext'
import { useNavigate } from 'react-router-dom'


function Header() {

    const { setLoggedOut, isAuthenticated, displayFooterNav, setDisplayFooterNav, setLanguage, language } = useContext(WorksContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [activeLink, setActiveLink] = useState('');

    function userLogout () {
        window.sessionStorage.removeItem("1");
        setLoggedOut();
        navigate('/'); 
      }
      
    useEffect(() => {
        const currentPath = location.pathname;
        setActiveLink(currentPath);
    }, [location.pathname]);

    function setEnglishLanguage(){
        setLanguage('en');
    }

    function setFrenchLanguage(){
        setLanguage('fr');
    }

    function handleDisplayFooterNav() {
        setDisplayFooterNav(!displayFooterNav);
      }

    return (
        <header className='header'>
            <div className='header_rightSide'>
                <Link to="/" className='header_rightSide_titles'>
                    <h1 className='header_rightSide_titles_h1'> BEN GIBERT </h1>
                    <h2 className='header_rightSide_titles_h2'> {language==="en"? 'Front-end developer' :'développeur front-end'}</h2>
                </Link>
                <div className='header_rightSide_navLanguage'>
                        <p className={language==='en'? 'header_rightSide_navLanguage_item header_rightSide_navLanguage_item--active' : 'header_rightSide_navLanguage_item header_rightSide_navLanguage_item--notActive'} onClick={() => setEnglishLanguage()} >EN</p>
                        <p className='header_rightSide_navLanguage_item'>|</p>
                        <p className={language==='fr'? 'header_rightSide_navLanguage_item header_rightSide_navLanguage_item--active' : 'header_rightSide_navLanguage_item header_rightSide_navLanguage_item--notActive'} onClick={() => setFrenchLanguage()}>FR</p>
                </div>
            </div>
            <div className='header_navSystem'>
                <div onClick={handleDisplayFooterNav} className={ location.pathname === '/' ? 'header_navSystem_gradientBars header_navSystem_gradientBars--displayOff' : 'header_navSystem_gradientBars header_navSystem_gradientBars--displayOn'}>
                    <div className='header_navSystem_gradientBars_gradientBar'></div>
                    <div className='header_navSystem_gradientBars_gradientBar'></div>
                    <div className='header_navSystem_gradientBars_gradientBar'></div>
                </div>
                <nav className={displayFooterNav === true ?'header_navSystem_nav header_navSystem_nav--displayOn' : 'header_navSystem_nav header_navSystem_nav--displayOff'}>
                    <Link to="/" className='header_navSystem_nav_link--displayOn' onClick={() => handleDisplayFooterNav()}><h2 className={activeLink === "/" ? 'header_navSystem_nav_link_h2 header_navSystem_nav_link_h2--active' : 'header_navSystem_nav_link_h2'}>{language==="en" ? 'HOME' : 'ACCUEIL' }</h2></Link>
                    <Link to="/about" className='header_navSystem_nav_link--displayOn' onClick={() => handleDisplayFooterNav()}><h2 className={activeLink === "/about" ? 'header_navSystem_nav_link_h2 header_navSystem_nav_link_h2--active' : 'header_navSystem_nav_link_h2'}>{language==="en" ? 'ABOUT' : 'À PROPOS' }</h2></Link>
                    <Link to="/works" className='header_navSystem_nav_link--displayOn' onClick={() => handleDisplayFooterNav()}><h2 className={activeLink === "/works" ? 'header_navSystem_nav_link_h2 header_navSystem_nav_link_h2--active' : 'header_navSystem_nav_link_h2'}>{language==="en" ? 'WORKS' : 'PROJETS' }</h2></Link>
                    <Link to="/services" className='header_navSystem_nav_link--displayOn' onClick={() => handleDisplayFooterNav()}><h2 className={activeLink === "/services" ? 'header_navSystem_nav_link_h2 header_navSystem_nav_link_h2--active' : 'header_navSystem_nav_link_h2'}>SERVICES</h2></Link>
                    <Link to="/skills" className='header_navSystem_nav_link--displayOn' onClick={() => handleDisplayFooterNav()}><h2 className={activeLink === "/skills" ? 'header_navSystem_nav_link_h2 header_navSystem_nav_link_h2--active' : 'header_navSystem_nav_link_h2'}>{language==="en" ? 'SKILLS' : 'COMPÉTENCES' }</h2></Link>
                    <Link to="/edit" className={isAuthenticated === true ? 'header_navSystem_nav_link--displayOn' :'header_navSystem_nav_link--displayOff'} onClick={() => handleDisplayFooterNav()}><h2 className={activeLink === "/edit" ? 'header_navSystem_nav_link_h2 header_navSystem_nav_link_h2--active' : 'header_navSystem_nav_link_h2'}>EDIT</h2></Link>
                    <button onClick={() => { userLogout(); handleDisplayFooterNav(); }} className={isAuthenticated === true ? 'header_navSystem_nav_logoutButton--displayOn' :'header_navSystem_nav_logoutButton--displayOff'} >LOGOUT</button>
                </nav>
            </div>
            
        </header>
    )
}

export default Header