import './ScrollArrow.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'


function ScrollArrow ({scrollArrowDisplay}) {
    
    return (
        <div className={scrollArrowDisplay === false ? "scrollArrow scrollArrow--displayOff" : "scrollArrow scrollArrow--displayOn"} >
            <FontAwesomeIcon icon={faChevronDown}/>
        </div>
    )
}

export default ScrollArrow