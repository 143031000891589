import React, { useState, useEffect, useContext } from 'react';
import servicesData from '../../datas/services.json';
import { WorksContext } from '../../utils/WorksContext';
import ScrollArrow from '../../components/ScrollArrow/ScrollArrow.js';
import './Services.scss';

function Services() {
    const { setFooterUnfold, setBackgroundStyle, scrollArrowDisplay, language } = useContext(WorksContext);
    const [services, setServices] = useState([]);
    const [activeServiceTitle, setActiveServiceTitle] = useState('Web development');
    const [activeIndex, setActiveIndex] = useState(0);
    const activeService = services[activeIndex];

    useEffect(() => {
        setFooterUnfold(true);
        setBackgroundStyle('services');
        setServices(servicesData);
        setActiveIndex(servicesData.findIndex(service => service.titleEn === activeServiceTitle));
    }, [activeServiceTitle, setBackgroundStyle, setFooterUnfold]);

    const handleServiceTitleClick = (event, title) => {
        event.preventDefault();
        event.stopPropagation();
        setActiveServiceTitle(title);
    };

    return (
        <section className='services'>
            <img src='../assets/summer.svg' alt='star' className="services_image"/>
            <div className='services_container'>
                {services.map((service, index) => (
                    <ul className='services_container_list' key={service.titleEn}>
                        <li className='services_container_list_item'>
                            <h3
                                className={`services_container_list_item--${activeServiceTitle === service.titleEn ? 'active' : 'inactive'}`}
                                onClick={(event) => handleServiceTitleClick(event, service.titleEn)}
                                onTouchStart={(event) => handleServiceTitleClick(event, service.titleEn)}
                            >
                                + {language === "en" ? service.titleEn : service.titleFr}
                            </h3>
                            <p className={`services_container_description ${service === activeService ? 'services_container_description--displayOn' : 'services_container_description--displayOff'}`}>
                                {activeService ? (language === "en" ? activeService.descriptionEn : activeService.descriptionFr) : ''}
                            </p>
                        </li>
                    </ul>
                ))}
            </div>
            <ScrollArrow scrollArrowDisplay={scrollArrowDisplay}/>
        </section>
    );
}

export default Services;