import './Error404.scss'
import { WorksContext } from '../../utils/WorksContext'
import React, { useContext, useEffect } from 'react'
 
function Error404() {

    const { setFooterUnfold, setBackgroundStyle, setDisplayFooterNav } = useContext(WorksContext);
    useEffect(() => {
        setFooterUnfold(true);
        setBackgroundStyle('home');
        setDisplayFooterNav(false);
    }, []);

    return  (      
        <div className='error404'>
            <p className='error404_404'>404</p>
            <p className='error404_text'>Oops... cette page semble ne pas exister.</p>
        </div>
    )
}

export default Error404