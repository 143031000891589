import './Works.scss'
import { useContext, useEffect } from 'react'
import { WorksContext } from '../../utils/WorksContext'
import { Link } from 'react-router-dom'


function Works() {

    const { works, setFooterUnfold, setBackgroundStyle, language, setDisplayFooterNav } = useContext(WorksContext);

    useEffect(() => {
        setFooterUnfold(true);
        setBackgroundStyle('about');
    }, []);
    
    return (
        <section className="works">
            <ul className='works_container'>
            {works.map((work) => (
                <li className='works_container_item' key={`${work.title}`}>
                    <Link to={`/works/${work._id}`} onClick={() => setDisplayFooterNav(false)}>
                        <h3 className='works_container_item_title'>+ {`${work.title}`}</h3>
                        <div className='works_container_item_subtitle'>
                            <p className='works_container_item_subtitle_year'>{`${work.year}`}</p>
                            <p className='works_container_item_subtitle_task'>-</p>
                            <p className='works_container_item_subtitle_task'>{language==='en' ? work.roleEn : work.roleFr}</p>
                        </div>
                    </Link>
                </li>
            ))}
            </ul>
        </section>
    )
}

export default Works