import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { WorksProvider, WorksContext } from './utils/WorksContext';
import './index.scss';
import Home from './pages/Home/Home'
import Works from './pages/Works/Works'
import Services from './pages/Services/Services'
import Skills from './pages/Skills/Skills'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Edit from './pages/Edit/Edit';
import About from './pages/About/About';
import Work from './pages/Work/Work';
import Error404 from './pages/Error404/Error404';
// import reportWebVitals from './reportWebVitals';


const AppWrapper = ({ children }) => {

  const { backgroundStyle, handleScroll } = useContext(WorksContext);

  useEffect(() => {
    handleScroll();
    // Ajoutez le gestionnaire d'événements scroll à la fenêtre
    window.addEventListener('scroll', handleScroll);
    // Nettoyer le gestionnaire d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);


  return (
    <div className="mainWrapper" onScroll={handleScroll} >
      <div className={`mainWrapper_image mainWrapper_image_${backgroundStyle}`}></div>
      {children}
    </div>
  )
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <WorksProvider>
        <AppWrapper>
          <Header/>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/services" element={<Services/>} />
              <Route path="/skills" element={<Skills/>} />
              <Route path="/edit" element={<Edit/>} />
              <Route path="/works" element={<Works/>} />
              <Route path="/works/:id" element={<Work/>} />
              <Route path="/*" element={<Error404/>} />
          </Routes>
          <Footer/>
        </AppWrapper>
      </WorksProvider>
    </HashRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
