
import './Footer.scss'
import AuthModal from '../AuthModal/AuthModal.js'
import { WorksContext } from '../../utils/WorksContext'
import React, { useContext, useState, useEffect } from 'react'

 
function Footer() {

  useEffect(() => {
    document.addEventListener('keydown', keyHandler, false);
    return () => {
      document.removeEventListener('keydown', keyHandler, false);
    };
  }, []);

  const { footerUnfold, language } = useContext(WorksContext);
  const [authModalDisplay, setAuthModalDisplay] = useState(false);

  const displayAuthModal = () => {
      setAuthModalDisplay(authModalDisplay === false ? true : false );
    }

  let konamiPattern = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
  let current = 0;

  const keyHandler = (event) => {
      if (konamiPattern.indexOf(event.key) < 0 || event.key !== konamiPattern[current])  {
          current = 0;
          return;
      }
      current++;
      if (konamiPattern.length === current) {
          current = 0;
          displayAuthModal();
      }
    }

  return (
    
    <footer className='footer'>
      <div className={footerUnfold===true ? 'footer_bottom footer_bottom--unfold' : 'footer_bottom footer_bottom--fold'}>
        <div className='footer_bottom_leftSide'>
          <nav className='footer_bottom_leftSide_socialNav'>
              <div className='footer_bottom_leftSide_socialNav_links'>
                  <a href='https://github.com/benngvolt' target="_blank" rel="noreferrer"><img src='./assets/github.svg' alt='github_logo' className='footer_bottom_leftSide_socialNav_links_logo'/></a>
                  <a href='https://www.linkedin.com/in/ben-gibert-591698198/' target="_blank" rel="noreferrer"><img src='./assets/linkedin.svg' alt='linkedIn_logo' className='footer_bottom_leftSide_socialNav_links_logo'/></a>
                  <a href='https://fr.fiverr.com/benngvolt/design-and-develop-your-website-or-web-application' target="_blank" rel="noreferrer"><img src='./assets/fiverr.svg' alt='fiverr_logo' className='footer_bottom_leftSide_socialNav_links_logo'/></a>
                  <a href='https://www.malt.fr/profile/benjamingibert' target="_blank" rel="noreferrer"><img src='./assets/malt.svg' alt='malt_logo' className='footer_bottom_leftSide_socialNav_links_logo'/></a>
              </div>
          </nav> 
          <div className='footer_bottom_leftSide_contact'>
            <img className='footer_bottom_leftSide_contact_planetIcon' src="./assets/planet.svg" alt="planet" width="40%" />
            <a className='footer_bottom_leftSide_contact_link' href="mailto:contact@benjamingibert.com">
              <p className='footer_bottom_leftSide_contact_link_text'> {language==="en"? 'SEND ME AN EMAIL' :'ENVOYEZ-MOI UN EMAIL'} </p>
              <img className='footer_bottom_leftSide_contact_link_messageButton' src="./assets/enveloppemagique.svg" alt="enveloppe" width="40%" />
            </a>
          </div>
        </div>
        <div className='footer_bottom_rightSide'>
          <div className={footerUnfold===true ? 'footer_bottom_rightSide_title footer_bottom_rightSide_title--unfold':'footer_bottom_rightSide_title footer_bottom_rightSide_title--fold'}>
            <p>W</p>
            <p>E</p>
            <p>L</p>
            <p>C</p>
            <p>O</p>
            <p>M</p>
            <p>E</p>
          </div>
          <p className={footerUnfold===true ? 'footer_bottom_rightSide_description footer_bottom_rightSide_description--unfold' : 'footer_bottom_rightSide_description footer_bottom_rightSide_description--fold'}>{language==="en"? 'A front-end developer based in Toulouse, France.' :'Développeur front-end basé à Toulouse'} <br/>©background photography: Florian Langellier <br/> ©2023 bengibert.com </p>
          <div className={footerUnfold===true ? 'footer_bottom_rightSide_gradientBar footer_bottom_rightSide_gradientBar--unfold':'footer_bottom_rightSide_gradientBar footer_bottom_rightSide_gradientBar--fold'}></div>
        </div>
        <AuthModal displayAuthModal={displayAuthModal} authModalDisplay={authModalDisplay}/>
      </div>
    </footer>
  )
}

export default Footer