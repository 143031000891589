import './AlertBox.scss'

function AlertBox({alertMessage, deletedWorkAlertDisplay}) {
    return (
        <div className={deletedWorkAlertDisplay === false ? "alertBox alertBox--displayOff" : "alertBox alertBox--displayOn"}>
            <div className='alertBox_container'>
                <p className='alertBox_container_text'>{alertMessage}</p>
            </div>
        </div>
    )
}

export default AlertBox