import React, { createContext, useState, useEffect } from 'react';
import { API_URL } from './constants'

export const WorksContext = createContext()

export const WorksProvider = ({ children }) => {
    
    const [works, setWorks] = useState([]);
    const [loadWorks, setLoadWorks] = useState(false);
    const [displayFooterNav, setDisplayFooterNav] = useState(false);
    const [scrollArrowDisplay, setScrollArrowDisplay] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [language, setLanguage] = useState('en');
    const [alertMessage, setAlertMessage] = useState('');
    const [footerUnfold, setFooterUnfold] = useState(false);
    const [backgroundStyle, setBackgroundStyle] = useState('home');

    const handleScroll = () => {
        const isBottom = window.scrollY >= (document.documentElement.scrollHeight - window.innerHeight);
        if (!isBottom) {
            setScrollArrowDisplay (true)
        } else {
            setScrollArrowDisplay (false)
        }
    };

    const handleLoadWorks = () => { 
        setLoadWorks(loadWorks === false ? true : false)
    }
    
    useEffect(() => {
        fetch(`${API_URL}/api/works`)
        .then((res) => res.json())
        .then((data) => setWorks(data),
            console.log('travaux chargés'),
        )
        .catch((error)=>console.log(error.message))
    },[loadWorks]);

    

    const setLoggedIn = () => {
        setIsAuthenticated(true) ;
    };

    const setLoggedOut = () => {
        setIsAuthenticated(false) ;
    };

    
    
    return (
        <WorksContext.Provider value={{ works, setWorks, setLoggedIn, setLoggedOut, isAuthenticated, alertMessage, setAlertMessage, handleLoadWorks, footerUnfold, setFooterUnfold, backgroundStyle, setBackgroundStyle, displayFooterNav, setDisplayFooterNav, scrollArrowDisplay, handleScroll, language, setLanguage}}>
            {children}
        </WorksContext.Provider>
    )
}