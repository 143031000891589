import './Skills.scss';
import skillData from '../../datas/skills.json';
import { useState, useEffect, useContext } from 'react';
import { WorksContext } from '../../utils/WorksContext';
import ScrollArrow from '../../components/ScrollArrow/ScrollArrow.js'

function Skills() {
    const [skills, setSkills] = useState([]);
    const { setFooterUnfold, setBackgroundStyle, scrollArrowDisplay, language} = useContext(WorksContext);
    const [activeSkillTitle, setActiveSkillTitle] = useState('Front-End');
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setFooterUnfold(true);
        setBackgroundStyle('skills');
    }, []);

    useEffect(() => {
        setSkills(skillData);
        setActiveIndex(skillData.findIndex(skill => skill.title === activeSkillTitle));
    }, [activeSkillTitle]);
    
    const activeSkill = skills[activeIndex];

    return ( 
        <section className='skills'>
            <img src='../assets/spring.svg' alt='star' className="skills_image"/>
            <ul className='skills_titleList'>
                {skills.map(skill => (
                    <li className='skills_titleList_item' key={skill.title}>
                        <h3 className={activeSkillTitle === `${skill.title}` ? 'skills_titleList_item--active' : 'skills_titleList_item--inactive'} onClick={() => setActiveSkillTitle(skill.title)}>+ {skill.title}</h3>   
                    </li>
                ))}   
            </ul>
            <ul className='skills_activeSkillList'>
                {activeSkill && activeSkill.underSkills ? (
                    activeSkill.underSkills.map((underSkill, index) => (
                        <li className='skills_activeSkillList_item' key={index}>
                            <h4>{underSkill.name}</h4>
                            <p>{language==='en' ? underSkill.descriptionEn : underSkill.descriptionFr}</p>
                        </li>
                    ))
                ) : null}
            </ul>
            <ScrollArrow scrollArrowDisplay={scrollArrowDisplay}/>
        </section>
    );
}

export default Skills;

