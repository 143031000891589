import './Home.scss'
import { Link } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import { WorksContext } from '../../utils/WorksContext'
import { useNavigate } from 'react-router-dom'

 
function Home() {

    const navigate = useNavigate();
    const { setLoggedOut, isAuthenticated, setFooterUnfold, setBackgroundStyle, setDisplayFooterNav, language } = useContext(WorksContext);
    
    useEffect(() => {
        setFooterUnfold(false);
        setBackgroundStyle('home');
        setDisplayFooterNav(false);
    }, []);

    function userLogout (event) {
        event.preventDefault();
        window.sessionStorage.removeItem("1");
        setLoggedOut();
        navigate('/');
    }

    return  (      
        <section className='homeSection'>
            <nav className='homeSection_nav'>
                <Link to="/about" className='homeSection_nav_link'><h2>{language==="en" ? '+ ABOUT' : '+ À PROPOS' }</h2></Link>
                <Link to="/works" className='homeSection_nav_link'><h2>{language==="en" ? '+ WORKS' : '+ PROJETS' }</h2></Link>
                <Link to="/skills" className='homeSection_nav_link'><h2>{language==="en" ? '+ TECHNOS' : '+ TECHNOS' }</h2></Link>
                <Link to="/services" className='homeSection_nav_link'><h2>+ SERVICES</h2></Link>
                <Link to="/edit" className={isAuthenticated === true ? 'homeSection_nav_logoutButton homeSection_nav_logoutButton--displayOn' :'homeSection_nav_logoutButton homeSection_nav_logoutButton--displayOff'}><h2>+ EDIT</h2></Link>
                <button onClick={userLogout} className={isAuthenticated === true ? 'homeSection_nav_logoutButton homeSection_nav_logoutButton--displayOn' :'homeSection_nav_logoutButton homeSection_nav_logoutButton--displayOff'}>+ LOGOUT</button>
            </nav>
        </section>
    )
}

export default Home