import './AuthModal.scss';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../utils/constants';
import { WorksContext } from '../../utils/WorksContext';

function AuthModal({ displayAuthModal, authModalDisplay }) {
  const { setLoggedIn, setLoggedOut } = useContext(WorksContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const authData = {
      username: formData.username,
      password: formData.password,
    };

    fetch(`${API_URL}/api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Réponse réseau non valide');
        }
        return response.json();
      })
      .then(({ token }) => {
        if (token) {
          const cleanedToken = token.replace(/"/g, '');
          setLoggedIn();
          displayAuthModal();
          navigate('/edit');
          window.sessionStorage.setItem('1', cleanedToken);
        } else {
          console.log("Non autorisé");
          setLoggedOut();
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className={`authModal ${authModalDisplay ? 'authModal--displayOn' : 'authModal--displayOff'}`}>
      <form onSubmit={onSubmit} className='authModal_form'>
        <p className='authModal_form_title'>AUTHENTIFICATION</p>
        <p className='authModal_form_alert'></p>
        <div>
          <label>USERNAME</label>
          <input
            type='text'
            name='username'
            id='inputUsername'
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>PASSWORD</label>
          <input
            type='password'
            name='password'
            id='inputPassword'
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type='submit'>LOGIN</button>
      </form>
      <button onClick={displayAuthModal} className='authModal_closeButton'>
        CLOSE
      </button>
    </div>
  );
}

export default AuthModal;