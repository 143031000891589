import './AddForm.scss'
import { useState, useRef, useEffect } from 'react'
import { useContext } from 'react'
import { WorksContext } from '../../utils/WorksContext'
import { API_URL } from '../../utils/constants'


function AddForm ({workObject, addFormDisplay, addWorkSubmit, formDisplay, modalMode}) {
    
    const work = workObject;

    const inputFileRef = useRef(null);
    const imageSampleRef = useRef(null);
    const inputTitleRef = useRef(null);
    const inputDescriptionEnRef = useRef(null);
    const inputDescriptionFrRef = useRef(null);
    const inputLinkRef = useRef(null);
    const inputGithubLinkRef = useRef(null);
    const inputChallengesEnRef = useRef(null);
    const inputChallengesFrRef = useRef(null);
    const inputTechnosRef = useRef(null);
    const inputYearRef = useRef(null);
    const inputRoleEnRef = useRef(null);
    const inputRoleFrRef = useRef(null);

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { handleLoadWorks } = useContext(WorksContext);

    useEffect(() => {
        if (addFormDisplay===true) {
            resetModalFields(modalMode);
            setErrorMessage("");
        }
    }, [modalMode, addFormDisplay]);


    function resetModalFields(modalMode) {
        setErrorMessage("");
        setIsImageLoaded(false);
        imageSampleRef.current.src = modalMode === 'edit' ? work.imageUrl : '';
        imageSampleRef.current.setAttribute("class", modalMode === 'edit' ? 'addForm_form_sampleContainer_sample' : '' );
        inputFileRef.current.value = null;
        inputTitleRef.current.value = modalMode === 'edit' ? work.title : '';
        inputLinkRef.current.value = modalMode === 'edit' ? work.linkUrl : '';
        inputDescriptionEnRef.current.value = modalMode === 'edit' ? work.descriptionEn : '';
        inputDescriptionFrRef.current.value = modalMode === 'edit' ? work.descriptionFr : '';
        inputYearRef.current.value = modalMode === 'edit' ? work.year : '';
        inputChallengesEnRef.current.value = modalMode === 'edit' ? work.challengesEn : '';
        inputChallengesFrRef.current.value = modalMode === 'edit' ? work.challengesFr : '';
        inputTechnosRef.current.value = modalMode === 'edit' ? work.technos : '';
        inputRoleEnRef.current.value = modalMode === 'edit' ? work.roleEn : '';
        inputRoleFrRef.current.value = modalMode === 'edit' ? work.roleFr : '';
        inputGithubLinkRef.current.value = modalMode === 'edit' ? work.githubLink : '';
    }


    function editWorkSubmit(event) {
        event.preventDefault();
        const workId = work._id;
        const workFormData = new FormData();

        workFormData.append('image', inputFileRef.current.files[0]);
        workFormData.append('title', inputTitleRef.current.value);
        workFormData.append('descriptionEn', inputDescriptionEnRef.current.value);
        workFormData.append('descriptionFr', inputDescriptionFrRef.current.value);
        workFormData.append('linkUrl', inputLinkRef.current.value);
        workFormData.append('challengesEn', inputChallengesEnRef.current.value);
        workFormData.append('challengesFr', inputChallengesFrRef.current.value);
        workFormData.append('technos', inputTechnosRef.current.value);
        workFormData.append('year', inputYearRef.current.value);
        workFormData.append('roleEn', inputRoleEnRef.current.value);
        workFormData.append('roleFr', inputRoleFrRef.current.value);
        workFormData.append('githubLink', inputGithubLinkRef.current.value);

        const token = window.sessionStorage.getItem('1');

        fetch(`${API_URL}/api/works/`+ workId,{
                method:"PUT",
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                body: workFormData,
            })
            .then((response) => {
                if (response.ok) {
                    return response;
                } else {
                    setErrorMessage("");
                    throw new Error('La requête a échoué');
                }
            })
            .then(() => {
                handleLoadWorks();
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage("Erreur lors de la modification ! ");
            });
    }


    function displaySample() {
        if(!inputFileRef.current.files || inputFileRef.current.files.length === 0) {
            setIsImageLoaded(false);
            return
        } else {
            const file = inputFileRef.current.files[0]; // récupération du fichier image dans le formulaire
            const reader = new FileReader(); // un objet FileReader est créé pour lire le contenu du fichier image sélectionné.
            reader.readAsDataURL(file); // lecture du fichier image récupéré comme adresse url
            reader.onload = function() { // création des attributs de l'image (src, alt, class)
                imageSampleRef.current.setAttribute("src", reader.result);
                imageSampleRef.current.setAttribute("alt", "");
                imageSampleRef.current.setAttribute("class", "addForm_form_sampleContainer_sample");
            }
            setIsImageLoaded(true);
        }
    }


    return (
        <div className={addFormDisplay === false ? 'addForm addForm--displayOff' : 'addForm addForm--displayOn'}>
            <form onSubmit={modalMode === 'edit' ? editWorkSubmit : addWorkSubmit} className='addForm_form'>
                <div  className="addForm_form_sampleContainer">
                    <img id='imageSample' ref={imageSampleRef} src={modalMode === 'edit' ? `${work.imageUrl}` : '' } className={modalMode === 'edit' ? 'addForm_form_sampleContainer_sample' : ''}  alt=''/>
                </div>
                <div className='addForm_form_imageFile'>
                    <label htmlFor='inputFile'>{isImageLoaded ? 'MODIFIER L\'IMAGE' : '+ AJOUTER UNE IMAGE'}</label>
                    <input type='file' id='inputFile' name="image" ref={inputFileRef} onChange={displaySample} defaultValue={modalMode === 'edit' ? '' : '' } style={{ display: 'none' }}></input>
                </div>
                <div className='addForm_form_name'>
                    <label htmlFor='inputTitle'>NOM DU PROJET</label>
                    <input type='text' id='inputTitle' ref={inputTitleRef} defaultValue= {modalMode === 'edit' ? `${work.title}` : ''} ></input>
                </div>
                <div className='addForm_form_year'>
                    <label htmlFor='inputYear'>ANNÉE</label>
                    <input type='number' id='inputYear' ref={inputYearRef} defaultValue= {modalMode === 'edit' ? `${work.year}` : ''} ></input>
                </div>
                <div className='addForm_form_role'>
                    <label htmlFor='inputRoleEn'>RÔLE_EN</label>
                    <input type='string' id='inputRoleEn' ref={inputRoleEnRef} defaultValue= {modalMode === 'edit' ? `${work.roleEn}` : ''} ></input>
                </div>
                <div className='addForm_form_role'>
                    <label htmlFor='inputRoleFr'>RÔLE_FR</label>
                    <input type='string' id='inputRoleFr' ref={inputRoleFrRef} defaultValue= {modalMode === 'edit' ? `${work.roleFr}` : ''} ></input>
                </div>
                <div className='addForm_form_description'>
                    <label htmlFor='inputDescriptionEn'>DESCRIPTION_EN</label>
                    <textarea type='textarea' id='inputDescriptionEn' ref={inputDescriptionEnRef} defaultValue= {modalMode === 'edit' ? `${work.descriptionEn}` : ''} ></textarea>
                </div>
                <div className='addForm_form_description'>
                    <label htmlFor='inputDescriptionFr'>DESCRIPTION_FR</label>
                    <textarea type='textarea' id='inputDescriptionFr' ref={inputDescriptionFrRef} defaultValue= {modalMode === 'edit' ? `${work.descriptionFr}` : ''} ></textarea>
                </div>
                <div className='addForm_form_linkUrl'>
                    <label htmlFor='inputLink'>LIEN</label>
                    <input type='link' id='inputLink' ref={inputLinkRef} defaultValue= {modalMode === 'edit' ? `${work.linkUrl}` : ''} ></input>
                </div>
                <div className='addForm_form_githubLink'>
                    <label htmlFor='inputGithubLink'>GITHUB</label>
                    <input type='link' id='inputGithubLink' ref={inputGithubLinkRef} defaultValue= {modalMode === 'edit' ? `${work.githubLink}` : ''} ></input>
                </div>
                <div className='addForm_form_functs'>
                    <label htmlFor='inputChallengesEn'>CHALLENGES_EN</label>
                    <textarea type='textarea' id='inputChallengesEn' ref={inputChallengesEnRef} defaultValue= {modalMode === 'edit' ? `${work.challengesEn}` : ''} ></textarea>
                </div>
                <div className='addForm_form_functs'>
                    <label htmlFor='inputChallengesFr'>CHALLENGES_FR</label>
                    <textarea type='textarea' id='inputChallengesFr' ref={inputChallengesFrRef} defaultValue= {modalMode === 'edit' ? `${work.challengesFr}` : ''} ></textarea>
                </div>
                <div className='addForm_form_technos'>
                    <label htmlFor='inputTechnos'>TECHNOLOGIES</label>
                    <textarea type='textarea' id='inputTechnos' ref={inputTechnosRef} defaultValue= {modalMode === 'edit' ? `${work.technos}` : ''} ></textarea>
                </div>
                <p className='addForm_form_errorText'>{errorMessage}</p>
                <div className='addForm_form_buttons'>
                    <button onClick={() => formDisplay()} type='button' className='addForm_form_buttons_cancelButton'>ANNULER</button>
                    <button type='submit' onClick={() => formDisplay()} className='addForm_form_buttons_validButton'>VALIDER</button>
                </div>
            </form>
        </div>
    )
}

export default AddForm