import './Edit.scss'
import AddForm from '../../components/AddForm/AddForm'
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox'
import AlertBox from '../../components/AlertBox/AlertBox'
import { useContext, useState, useEffect } from 'react'
import { WorksContext } from '../../utils/WorksContext'
import { API_URL } from '../../utils/constants'


function Edit() {

    const { handleLoadWorks, setFooterUnfold, setBackgroundStyle, alertMessage, works, isAuthenticated } = useContext(WorksContext);
    const [deletedWorkAlertDisplay, setDeletedWorkAlertDisplay] = useState(false);
    const [addFormDisplay, setAddFormDisplay] = useState(false);
    const [confirmBoxDisplay, setConfirmBoxDisplay] = useState(false);
    const [workObject, setWorkObject] = useState(null);
    const  [modalMode, setModalMode] = useState('add');
    
    useEffect(() => {
        setFooterUnfold(true);
        setBackgroundStyle('work');
    }, []);
    
    const deletedWorkAlert = () => {
        setDeletedWorkAlertDisplay (deletedWorkAlertDisplay === false ? true : false );
        setTimeout(() => {
        setDeletedWorkAlertDisplay(false);
        }, 2000);
    }
    
    const formDisplay = () => {
        setAddFormDisplay(addFormDisplay === false ? true : false );
      }

    
    const confirmDisplay = () => {
        setConfirmBoxDisplay(confirmBoxDisplay === false ? true : false );
      }

    const storeWork = (work) => {
        setWorkObject(work);
    }
   
    const switchAddMode = () => {
        setModalMode('add');
    }

    const switchEditMode = () => {
        setModalMode('edit');
    }

    function addWorkSubmit(event) {
        event.preventDefault();
        const inputFile = document.getElementById('inputFile');
        const inputTitle = document.getElementById('inputTitle');
        const inputDescription = document.getElementById('inputDescription');
        const inputLink = document.getElementById('inputLink');
        const inputChallenges = document.getElementById('inputChallenges');
        const inputTechnos = document.getElementById('inputTechnos');
        const inputYear = document.getElementById('inputYear');
        const inputRole = document.getElementById('inputRole');
        const inputGithubLink = document.getElementById('inputGithubLink');
    
        const workFormData = new FormData();
    
        workFormData.append('image', inputFile.files[0]);
        workFormData.append('title', inputTitle.value);
        workFormData.append('description', inputDescription.value);
        workFormData.append('linkUrl', inputLink.value);
        workFormData.append('githubLink', inputGithubLink.value);
        workFormData.append('role', inputRole.value);
        workFormData.append('year', inputYear.value);
        workFormData.append('technos', inputTechnos.value);
        workFormData.append('challenges', inputChallenges.value);
        
        const token = window.sessionStorage.getItem('1');
        
        fetch(`${API_URL}/api/works`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body: workFormData,
            })
            .then((response) => {
                if (response.ok) {
                    return response;
                } else {
                    throw new Error('La requête a échoué');
                }
            })
            .then(() => {
                handleLoadWorks();
            })
            .catch((error) => console.error(error));
    }

    
    return (
        <div className = 'editPage'>
            <p className={isAuthenticated === true ? 'editPage_alertMessage--displayOff':'editPage_alertMessage--displayOn'}>ACCÈS NON-AUTORISÉ</p>
            <section className={isAuthenticated === true ? 'editPage_editSection editPage_editSection--displayOn':'editPage_editSection--displayOff'}>
                <ul className='editPage_editSection_container'>
                    {works.map((work) => 
                    <li className='editPage_editSection_container_item' key={`${work.title}`}>
                        <img src={`${work.imageUrl}`} alt=''/>
                        <h3>{`${work.title}`}</h3>
                        <div className='editPage_editSection_container_item_buttons'>
                            <button className='editPage_editSection_container_item_buttons_modifButton' onClick={() => {
                                storeWork(work);
                                formDisplay();
                                switchEditMode ();
                                }}>MODIFIER</button>
                            <button className='editPage_editSection_container_item_buttons_supprButton' onClick={() => {
                                confirmDisplay();
                                storeWork(work)
                                }}>SUPPRIMER</button>
                        </div>
                    </li>
                    )
                }
                    <li>
                        <AlertBox alertMessage={alertMessage} deletedWorkAlertDisplay={deletedWorkAlertDisplay} deletedWorkAlert={deletedWorkAlert}/>
                    </li>
                </ul>
                <button className='editPage_editSection_addButton' onClick={() => {
                    formDisplay();
                    switchAddMode ();
                    }}>+ AJOUTER UN PROJET</button>
                <ConfirmBox workObject={workObject} confirmBoxDisplay={confirmBoxDisplay} confirmDisplay={confirmDisplay} deletedWorkAlert={deletedWorkAlert}/>
                <AddForm workObject={workObject} addFormDisplay={addFormDisplay} addWorkSubmit={addWorkSubmit} formDisplay={formDisplay} modalMode={modalMode}  />
            </section>
        </div>
    )
}

export default Edit

