import './ConfirmBox.scss'
import { WorksContext } from '../../utils/WorksContext'
import React, { useContext } from 'react'
import { API_URL } from '../../utils/constants'

    
function ConfirmBox({workObject, confirmBoxDisplay, confirmDisplay, setDeletedWorkAlertDisplay, deletedWorkAlert}) {

    const work = workObject;
    const { setAlertMessage } = useContext(WorksContext);
    const { handleLoadWorks } = useContext(WorksContext);

    function deleteWork(work) {
        
        const token = window.sessionStorage.getItem('1');
        setAlertMessage ('Projet supprimé !');
        deletedWorkAlert();
        fetch(`${API_URL}/api/works/` + work._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token, // Inclure le token dans l'en-tête d'autorisation
                },   
            })
            .then(() => {
                handleLoadWorks();
            })
            .catch((error) => console.error(error))
    }

    return (
        <div className={confirmBoxDisplay === false ? "confirmBox confirmBox--displayOff" : "confirmBox confirmBox--displayOn"}>
            <div className='confirmBox_container'>
                <p className='confirmBox_container_question'>Voulez-vous vraiment supprimer ce projet?</p>
                <div className='confirmBox_container_buttons'>
                    <button onClick={() => {
                        deleteWork(work);
                        confirmDisplay()
                        }}>OUI</button>
                    <button onClick={() => confirmDisplay()}>NON</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmBox